import os from 'os';

import _ from 'lodash';

import { deepFreeze } from './util';
import Colors from './colors';

const DEVMODE = process.env.NODE_ENV === 'development';

const MINUTE = 60 * 1000;

const DEFAULT_TIME_LIMIT = 15 * MINUTE;
const DEFAULT_PATRON_TIME_LIMIT = DEFAULT_TIME_LIMIT * 1;

const DEFAULT_ITERATIONS_LIMIT = 300000;

const DEFAULT_COPY_ACTOR_LIMIT = 60;
const MAX_COPY_ACTORS = 200;

const HIGH_PRECISION = 0.05;
const LOW_PRECISION = DEVMODE ? 0.25 : 0.1;

const MILLION = 1000000;

export default deepFreeze({
  currentExpansion: 9,

  wowEnvs: [{
    key: 'live',
    wowKey: 'retail',
    product: 'wow',
    name: 'Retail',
    wowheadSubdomain: 'www',
    color: Colors.green,
    simcBranch: 'thewarwithin',
  }, {
    key: 'ptr',
    wowKey: 'ptr',
    product: 'wowt',
    name: 'PTR 1',
    wowheadSubdomain: 'ptr',
    color: Colors.orange,
    simcBranch: 'thewarwithin',
  }, {
    key: 'xptr',
    wowKey: 'xptr',
    product: 'wowxptr',
    name: 'PTR 2',
    wowheadSubdomain: 'ptr-2',
    color: Colors.orange,
    simcBranch: 'thewarwithin',
  }, {
    key: 'beta',
    wowKey: 'beta',
    product: 'wow_beta',
    name: 'Beta',
    wowheadSubdomain: 'beta',
    color: Colors.red,
    simcBranch: 'thewarwithin',
  }],

  wowEnvMap: {
    live: 'live',
    test: 'ptr',
    //beta: 'beta',
  },

  links: {
    support: 'mailto:support@raidbots.com',
    supportSite: 'https://support.raidbots.com/',
    discord: 'https://discord.gg/86EF64Q',
    unsubscribe: 'https://support.raidbots.com/article/14-can-i-unsubscribe-from-raidbots-emails',
    simcAddon: 'https://www.curseforge.com/wow/addons/simulationcraft',
  },

  // Listen ports
  port: {
    http: {
      worker: 4414,
      web: DEVMODE ? 80 : 8080,
      kue: 3000,
      flightmaster: 4415,
      jobserver: 4416,
      discordbot: 4417,
    },
  },

  features: {
    armory: true,
  },

  beta: {
    simcVersion: 'latest',
    features: {
      armory: true,
      inputValidation: false,
    },
    forcePtr: true,
    wowEnv: 'test',
  },

  legal: {
    touVersion: new Date('26 Oct 2023 12:00:00 PST'),
  },

  flightmaster: {
    // how many jobs should a single flightmaster manage
    jobsPerProcess: DEVMODE ? 2 : 3,

    // expire time for simc redis logs
    simcLogTtl: 60 * 60 * 6,

    // how many log lines to keep
    simcLogLength: 10,

    // default worker concurrency
    defaultConcurrency: 1,

    // flight timeout is set a bit higher than job timeout to try to let worker's handle timeouts in non-chunk cases
    flightTimeoutMultiplier: 1.1,

    // restart chunk if it has not started after this much time
    chunkRestart: 30 * 1000,

    // target_error >= this level will use profileset_work_threads
    psetWorkThreadThreshold: 0.5,

    // value for profileset_work_threads
    psetWorkThreadAmount: 2,

    psetAnalyzeErrorInterval: 50,

    // threshold of psets to report to keep JSON size down
    largeProfilesetCount: DEVMODE ? 250 : 250,

    // switch to multistage when more than this number of psets
    multistageThreshold: 4,

    // make this available on the config
    highPrecision: HIGH_PRECISION,
    lowPrecision: LOW_PRECISION,

    // high precision multistage target errors to use
    multiPrecisionDefault: DEVMODE ? [
      2,
      0.4,
      HIGH_PRECISION,
    ] : [
      1,
      0.2,
      HIGH_PRECISION,
    ],

    // low precision multistage target errors to use
    multiPrecisionFast: DEVMODE ? [
      2,
      0.75,
      LOW_PRECISION,
    ] : [
      1,
      0.3,
      LOW_PRECISION,
    ],

    // target chunk sizes to use for each stage
    // actual chunk size may vary due to chunk balancing and concurrency
    // this is in reverse order from the errors above (first size is used for highest precision)
    // it's a bit annoying but it makes it a bit easier to play with more stage configs if desired
    chunkSizes: DEVMODE ? [8, 32, 256] : [8, 32, 256],

    // keep actors within (errorThreshold * dpsError * error)
    errorThreshold: 2.5,

    // used for tracking counts of queue times that are longer than desired
    longQueuePublic: 7 * 60 * 1000,
    longQueuePremium: 30 * 1000,
  },

  loggingThrottleMs: 200,

  worker: {
    numCores: DEVMODE ? (_.max([_.floor(os.cpus().length / 2) - 2, 0]) || 32) : 32,
    logRedisModulo: 3,
  },

  redis: {
    apiKeyJobsExpiry: 60 * 30,
    rateLimitKeyExpiry: 60 * 10,
  },

  // shows warnings/errors on input when input versions are too low
  addon: {
    simc: {
      minVersion: '11.1.0',
    },
    wow: {
      minVersion: '11.1.0',
    },
  },

  discord: {
    reward: {
      maxMemberCount: 100,
      largeGuildThreshold: 200, // for requiring admin role to assign rewards
    },
  },

  optimize: {
    // getRawCombinations bails out when the cartesian product is larger than this
    maxRawCombinations: 200000,

    // getCombinations bails out if getRawCombinations returns more than this value
    maxCombinations: 10000,

    // Top Gear download fails when getRawCombinations returns more than this
    maxCombinationsDownload: 10000,

    // getCombinations bails out at this number of combos
    maxTotalCombinations: 10000,

    // top end of iterations for non-smart sims
    smartThreshold: 2000000,

    maxWalkPartials: 5000000,

    maxContaminants: 9,

    maxEnhancements: {
      main_hand: 9,
      off_hand: 9,
      back: 4,
      chest: 4,
      bracer: 4,
      glove: 4,
      legs: 4,
      feet: 4,
      finger: 4,
      wrist: 4,
      hands: 4,
      socket: 13,
    },

    // max number of combinations allowed when using replace existing gem/enchants
    //maxReplaceEnhancementCombinations: 3200, // 16m iterations
  },

  stripe: {
    publicKey: DEVMODE ? 'pk_test_jg3mWa7M0q7motw4y0hidxsn' : 'pk_live_rR7VnDnPTM5GIi4zYiWopUOM',

    // block premium for an account if they dispute this many times
    blockAfterDisputesCount: 5,
    // block premium for an account if they consistently have failed async payments
    blockAfterIncompleteExpired: 5,
  },

  account: {
    emailVerifyRateMinutes: 15,
    emailVerifyNagDays: 1,
  },

  // Kue configs
  queue: {
    simc: {
      attempts: 4,
      backoff: {
        delay: 2 * 1000,
        type: 'exponential',
      },
      removeOnComplete: false,
      // how long to wait for no response from worker before killing job
      ttl: 2 * 60 * 1000,
    },
    flight: {
      attempts: 9,
      backoff: {
        delay: 2 * 1000,
        type: 'exponential',
      },
      removeOnComplete: false,
      // how long to wait for no response from worker before killing job
      ttl: 60 * 1000,
    },
    simcReport: {
      attempts: 5,
      backoff: {
        delay: 1 * 1000,
        type: 'exponential',
      },
      delay: 500,
      removeOnComplete: true,
      timeout: 10000,
    },
    browserScreenshot: {
      attempts: 5,
      backoff: {
        delay: 2 * 1000,
        type: 'exponential',
      },
      timeout: 10000,
    },
    simcInputValidate: {
      attempts: 0,
      timeout: 2000,
    },
    userDownload: {
      attempts: 5,
      timeout: 12 * 60 * 60 * 1000,
    },
    discordUserRefresh: {
      attempts: 5,
      timeout: 60 * 1000, // bee
      backoff: {
        delay: 2 * 1000,
        type: 'exponential',
      },
      delay: 1000,
      removeOnComplete: true,
    },
    dbcache: {
      attempts: 5,
      timeout: 60 * 1000, // bee
      backoff: {
        delay: 2 * 1000,
        type: 'exponential',
      },
      delay: 1000,
      removeOnComplete: true,
    },
    admin: {
      attempts: 5,
      timeout: 60 * 1000,
    },
    adminSimcBuild: {
      attempts: 3,
      timeout: 30 * 60 * 1000,
    },
    adminUpdateGameData: {
      attempts: 3,
      timeout: 30 * 60 * 1000,
    },
  },

  iterationsLimit: DEFAULT_ITERATIONS_LIMIT,

  reports: {
    htmlComboLimit: 20,
  },

  dataDownload: {
    requestFrequency: DEVMODE ? 60 * 1000 : 24 * 60 * 60 * 1000,
  },

  apiTokens: [{
    name: 'Discord Bot - lithium',
    token: 'cdb3fd02d9654304c9bd49075e94ef852b6f791e9f7ee4ddb20ad7abeb088920207d4993f02edd0f255dce245d36aab9',
    origin: 'discord',
    grants: [
      'patreonTitle',
    ],
  }, {
    name: 'Blingtron Server',
    token: '1f23930954ed5a2f2412b721618877d343adca39',
    origin: 'blingtron',
    grants: [
      'patreonTitle',
    ],
  }, {
    name: 'API',
    token: 'apiToken',
    origin: 'api',
  }],

  userLevels: {
    default: {
      level: 0,
      noAds: true,
      iterationsLimit: DEFAULT_ITERATIONS_LIMIT,
      advancedActorLimit: _.min([DEFAULT_COPY_ACTOR_LIMIT, MAX_COPY_ACTORS]),
      simTimeLimit: DEFAULT_TIME_LIMIT,
      queuePriority: 'low', // 10
      patronChannel: false,
      guildDiscordPriority: false,
      apiAccess: false,
      concurrency: 1,
      beta: false,
    },
    registered: {
      level: 10,
      noAds: true,
      iterationsLimit: DEFAULT_ITERATIONS_LIMIT,
      advancedActorLimit: _.min([DEFAULT_COPY_ACTOR_LIMIT, MAX_COPY_ACTORS]),
      simTimeLimit: DEFAULT_TIME_LIMIT,
      queuePriority: 'low', // 10
      patronChannel: false,
      guildDiscordPriority: false,
      apiAccess: false,
      concurrency: 1,
      beta: false,
    },
    uncommon: {
      level: 20,
      patreonTitle: 'Uncommon',
      noAds: true,
      iterationsLimit: 1.8 * MILLION,
      stripeIterationsLimit: 2 * MILLION,
      advancedActorLimit: _.min([2 * DEFAULT_COPY_ACTOR_LIMIT, MAX_COPY_ACTORS]),
      simTimeLimit: 2 * DEFAULT_PATRON_TIME_LIMIT,
      queuePriority: 'normal', // 0
      patronChannel: true,
      guildDiscordPriority: false,
      color: '#1eff00',
      apiAccess: false,
      droptimizerAzerite: true,
      concurrency: 1,
      beta: false,
    },
    rare: {
      level: 30,
      patreonTitle: 'Rare',
      noAds: true,
      iterationsLimit: 1.8 * MILLION,
      stripeIterationsLimit: 2 * MILLION,
      advancedActorLimit: _.min([2 * DEFAULT_COPY_ACTOR_LIMIT, MAX_COPY_ACTORS]),
      simTimeLimit: 2 * DEFAULT_PATRON_TIME_LIMIT,
      queuePriority: 'normal', // 0
      patronChannel: true,
      guildDiscordPriority: false,
      color: '#0070dd',
      apiAccess: false,
      droptimizerAzerite: true,
      concurrency: 1,
      beta: false,
    },
    heirloom: {
      level: 40,
      patreonTitle: 'Heirloom',
      noAds: true,
      iterationsLimit: 5 * MILLION,
      stripeIterationsLimit: 5.5 * MILLION,
      advancedActorLimit: _.min([4 * DEFAULT_COPY_ACTOR_LIMIT, MAX_COPY_ACTORS]),
      simTimeLimit: 2 * DEFAULT_PATRON_TIME_LIMIT,
      queuePriority: 'normal', // 0
      patronChannel: true,
      guildDiscordPriority: false,
      color: '#00ccff',
      apiAccess: false,
      droptimizerAzerite: true,
      concurrency: 1,
      beta: false,
    },
    epic: {
      level: 50,
      patreonTitle: 'Epic',
      noAds: true,
      iterationsLimit: 15 * MILLION,
      stripeIterationsLimit: 16 * MILLION,
      advancedActorLimit: _.min([8 * DEFAULT_COPY_ACTOR_LIMIT, MAX_COPY_ACTORS]),
      simTimeLimit: 4 * DEFAULT_PATRON_TIME_LIMIT,
      queuePriority: 'normal', // 0
      patronChannel: true,
      guildDiscordPriority: true,
      color: '#a335ee',
      apiAccess: false,
      droptimizerAzerite: true,
      concurrency: 2,
      beta: false,
    },
    legendary: {
      level: 60,
      patreonTitle: 'Legendary',
      noAds: true,
      iterationsLimit: 15 * MILLION,
      stripeIterationsLimit: 16 * MILLION,
      advancedActorLimit: _.min([8 * DEFAULT_COPY_ACTOR_LIMIT, MAX_COPY_ACTORS]),
      simTimeLimit: 4 * DEFAULT_PATRON_TIME_LIMIT,
      queuePriority: 'normal', // 0
      patronChannel: true,
      guildDiscordPriority: true,
      color: '#ff8000',
      apiAccess: false,
      droptimizerAzerite: true,
      concurrency: 2,
      beta: false,
    },
    theorycrafter: {
      level: 70,
      patreonTitle: 'Theorycrafter',
      noAds: true,
      iterationsLimit: 16 * MILLION,
      stripeIterationsLimit: 16 * MILLION,
      advancedActorLimit: _.min([8 * DEFAULT_COPY_ACTOR_LIMIT, MAX_COPY_ACTORS]),
      simTimeLimit: 8 * DEFAULT_PATRON_TIME_LIMIT,
      queuePriority: 'normal', // 0
      patronChannel: true,
      guildDiscordPriority: true,
      color: '#a84300',
      apiAccess: true,
      droptimizerAzerite: true,
      concurrency: 2,
      beta: false,
    },
    simcdev: {
      level: 80,
      patreonTitle: 'SimC Dev',
      noAds: true,
      iterationsLimit: 16 * MILLION,
      stripeIterationsLimit: 16 * MILLION,
      advancedActorLimit: _.min([32 * DEFAULT_COPY_ACTOR_LIMIT, MAX_COPY_ACTORS]),
      simTimeLimit: 32 * DEFAULT_PATRON_TIME_LIMIT,
      queuePriority: 'normal', // 0
      patronChannel: true,
      guildDiscordPriority: true,
      color: '#f91590',
      apiAccess: true,
      droptimizerAzerite: true,
      concurrency: 2,
      beta: true,
    },
    artifact: {
      level: 100,
      patreonTitle: 'Artifact',
      noAds: true,
      iterationsLimit: 32 * MILLION,
      stripeIterationsLimit: 32 * MILLION,
      advancedActorLimit: _.min([8 * DEFAULT_COPY_ACTOR_LIMIT, MAX_COPY_ACTORS]),
      simTimeLimit: 20 * DEFAULT_PATRON_TIME_LIMIT,
      queuePriority: 'normal', // 0
      patronChannel: true,
      guildDiscordPriority: true,
      color: '#e6cc80',
      apiAccess: true,
      droptimizerAzerite: true,
      concurrency: 2,
      beta: true,
    },
  },
});
